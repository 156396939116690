import cn from 'classnames';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { memo, useEffect, useState } from 'react';
import { StickyContainer } from 'react-sticky';

import { useIsMobile } from '@sravni/react-utils';

import type { CommonCardProps } from '@src/@types/commonCardProps';
import { PageHeaderSponsor } from '@src/components/PageHeader';
import { ProductList as ProductListCommon } from '@src/components/ProductList';
import { usePageContext } from '@src/config/PageContext';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { DEFAULT_LIMIT, FILTERS_NAMES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import type { IFilter } from '@src/hooks/useFilters';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import { isMainPage, isSpecialPageWithoutQuery } from '@src/utils/routing';

import { ShowMoreButton } from './ShowMoreButton';
import { SortPanel } from './SortPanel';
import styles from './styles.module.scss';

interface IProps {
  className?: string;
  listClassName?: string;
  gridLayout?: boolean;

  pathname?: string;
  filter: IFilter;
  showMoreButton?: ReactNode;
  renderCard?: (props: CommonCardProps) => ReactNode;
}

// eslint-disable-next-line complexity
const ProductList: React.FC<IProps> = memo(
  ({ className, listClassName, gridLayout, pathname, filter, showMoreButton, renderCard }) => {
    const { listKey, sortPanelVisible } = usePageContext();
    const { items, total = 0, loadingMore } = useSelectCreditsList(listKey);
    const { query, asPath } = useRouter();
    const filterValue = filter.filters[FILTERS_NAMES.OFFERS_TYPE];
    const offerType = filterValue || 'new';
    const isMobile = useIsMobile();
    const isSpecial = isSpecialPageWithoutQuery(asPath);

    const limit = filter.filters[FILTERS_NAMES.LIMIT] || DEFAULT_LIMIT;
    const activeElements = items?.length || 0;
    const loadItemCount = total - activeElements;
    const preloadTotal = loadItemCount > limit ? limit : loadItemCount;
    const isMain = Boolean(pathname && isMainPage(pathname, query));
    const isNonSeoPage = isMain || listKey !== ListKeys.LIST;
    const isRenderShowMoreButton: boolean =
      (preloadTotal && activeElements) > 0 && [total, !loadingMore, total !== activeElements].some(Boolean);

    const [isRenderRedirectMoreOffersButton, setIsRenderRedirectMoreOffersButton] = useState({
      asPath,
      isRender: isNonSeoPage ? false : !isRenderShowMoreButton,
    });

    useEffect(() => {
      if (isNonSeoPage) {
        setIsRenderRedirectMoreOffersButton({ asPath, isRender: false });
        return;
      }
      const pathWasChanged = isRenderRedirectMoreOffersButton.asPath !== asPath;
      if (pathWasChanged || isRenderShowMoreButton) {
        setIsRenderRedirectMoreOffersButton({ asPath, isRender: !isRenderShowMoreButton });
      }
    }, [isRenderRedirectMoreOffersButton.asPath, isRenderShowMoreButton, asPath, isNonSeoPage, listKey]);

    const isSortPanelShowed = sortPanelVisible && Boolean(items?.length) && offerType === 'new' && !isMobile;

    return (
      <StickyContainer id={PRODUCT_LIST_ANCHOR} className={cn(styles.sticky_container, className)}>
        {isMobile && (
          <section className={styles.page_header}>
            <PageHeaderSponsor />
          </section>
        )}

        {isSortPanelShowed && (
          <SortPanel sort={filter.filters[FILTERS_NAMES.SORT]} onChange={filter.setFilter} hasSortLabel />
        )}

        <ProductListCommon
          preloadTotal={preloadTotal}
          filter={filter}
          renderCard={renderCard}
          className={listClassName}
          gridLayout={gridLayout}
        />

        {isRenderShowMoreButton &&
          !isSpecial &&
          (showMoreButton || (
            <ShowMoreButton
              className={styles.show_more}
              listKey={listKey}
              preloadTotal={preloadTotal}
              loadItemCount={loadItemCount}
            />
          ))}
      </StickyContainer>
    );
  },
);

export default ProductList;
